export const portfolioData = [
  {
    id: 1,
    name: 'Safesanté',
    languages: ['java', 'angular', 'css'],
    languagesIcons: ['fab fa-java', 'fab fa-angular','fab fa-css3-alt'],
    source: 'http://www.safesante.fr',
    title: "Participation à l'amélioration de l'application web",
    info: "Safesanté est une plateforme dédiée aux médecins et à leurs patients. Il est possible de prendre des rendez-vous et d'effectuer des visio consultations",
    picture: './media/safesante.png'
  },
  {
    id: 2,
    name: 'Chocolatier',
    languages: ['wordpress','css'],
    languagesIcons: ['fab fa-wordpress','fab fa-css3-alt'],
    source: 'http://www.remygalopin.fr/projets/chocolatier',
    title: "Second exercice d'initiation à Wordpress",
    info: 'Chocolatier est un site sur le thème des chocolats de Jean-François Gourmand. Ce site est déployé avec Wordpress et du code CSS personnalisé.',
    picture: './media/chocolatier.jpg'
  },
  {
    id: 3,
    name: 'Le Maté',
    languages: ['wordpress','css'],
    languagesIcons: ['fab fa-wordpress','fab fa-css3-alt'],
    source: 'http://www.remygalopin.fr/le_mate',
    title: "Premier exercice d'initiation à Wordpress",
    info: "Le Maté est un site internet sur le thème de la boisson nommée maté, rendue populaire notamment par le biais de plusieurs célébrités.",
    picture: './media/mate.png'
  },
  {
    id: 4,
    name: 'Starfood',
    languages: ['java','angular', 'css','sql'],
    languagesIcons: ['fab fa-java', 'fab fa-angular', 'fab fa-css3-alt', 'fas fa-database'],
    source: 'https://github.com/Legacy0013',
    title: "Second projet réalisé lors de ma formation concepteur développeur d'applications",
    info: "Starfood est une application de type borne de fast food, permettant d'effectuer commandes et paiements.",
    picture: './media/starfood.png'
  },
  {
    id: 5,
    name: 'Alexandria',
    languages: ['java','jsp', 'css', 'sql'],
    languagesIcons: ['fab fa-java', 'fas fa-file-code', 'fab fa-css3-alt', 'fas fa-database'],
    source: 'http://www.github.com/Legacy0013',
    title: "Premier projet réalisé lors de ma formation concepteur développeur d'applications",
    info: "Alexandria est application métier de type librairie, permettant l'ajout et la suppression de livres.",
    picture: './media/alexandria.png'
  },
  {
    id: 6,
    name: 'Portfolio V1',
    languages: ['php','javascript', 'css'],
    languagesIcons: ['fab fa-php', 'fab fa-js-square', 'fab fa-css3-alt'],
    source: 'http://www.remygalopin.fr/projets/portfolioV1',
    title: "Première version de mon portfolio",
    // info: "Première version de mmon portfolio",
    picture: './media/portfolioV1.png'
  },
]