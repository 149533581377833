import React, { useEffect, useRef } from 'react';
import {expComponent} from '../Animate';

const Experiences = () => {

    //using useRef hook to access the expComponent DOM
    let experiences = useRef(null)
    useEffect(() => {
    expComponent(experiences)
    }, [])

      return (
        <div className="experiences" ref={(el) => (experiences = el)}>
            <h3>Expériences</h3>
            <div className="exp-1">
                <h4>Stagiaire Développeur full-stack</h4>
                <h5>Safesanté du 07-2019 au 08-2019</h5>
                <ul>
                    <li>Participation à l’amélioration de la plateforme Safesanté</li>
                    <li>Mise en place de web services REST</li>
                    <li>Gestion du responsive via media queries & boostrap</li>
                    <li>Gestion du projet sur Bitbucket et mises à jour des versions via TortoiseGit</li>
                </ul>
            </div>
            <div className="exp-2">
                <h4>Webdesigner / intégrateur</h4>
                <h5>CFI Web agency du 11-2010 à 04-2011</h5>
                <ul>
                    <li>Identification des besoins client</li>
                    <li>Réalisations de maquettes</li>
                    <li>Création de logos et retouche d'images</li>
                    <li>Conception, réalisation et publication de sites web</li>
                    <li>Réalisation d'e-mailing et newsletters</li>
                </ul>
            </div>
            <div className="exp-3">
                <h4>Webdesigner / intégrateur</h4>
                <h5>Websiting du 07-2010 au 10-2010</h5>
                <ul>
                    <li>Création de logos et de chartes graphiques</li>
                    <li>Réalisation sites web</li>
                    <li>Référencement naturel et Google Adwords</li>
                </ul>
            </div>
        </div>
    );
};

export default Experiences;