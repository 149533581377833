import React, { useEffect, useRef } from 'react';
import {otherComponent} from '../Animate';

const OtherSkills = () => {

//using useRef hook to access the otherComponent DOM
    let otherSkills = useRef(null)
    useEffect(() => {
        otherComponent(otherSkills)
    }, [])

    return (
        <div className="otherSkills" ref={(el) => (otherSkills = el)}>
            <h3>Autres compétences</h3>
            <div className="list">
            <ul>
                <li><i className="fas fa-check-square"></i>Github</li>
                <li><i className="fas fa-check-square"></i>SEO</li>
                <li><i className="fas fa-check-square"></i>Méthodes agiles</li>
            </ul>
            <ul>
                <li><i className="fas fa-check-square"></i>Figma</li>
                <li><i className="fas fa-check-square"></i>Adobe XD</li>
                <li><i className="fas fa-check-square"></i>UX/UI Design</li>
                <li><i className="fas fa-check-square"></i>Photoshop</li>
            </ul>
            </div>
        </div>
    );
};

export default OtherSkills;