import React from 'react';
import QueueAnim from 'rc-queue-anim';

export default class Project extends React.Component {

    state = {

        showInfo: false
    }


    handleInfo = () => {
        this.setState({
          showInfo:!this.state.showInfo 
        }) 
    }
  

    render() {

        let {name, languagesIcons, source, title, info, picture} = this.props.item;

        return (
            <div className="project" key='1'>
                <QueueAnim
                    type='scale'
                    delay={100}
                    duration={1000}
                >
                    
                        <div className="icons" key='2'>
                            {languagesIcons.map(icon =>
                                    <i className={icon} key={icon}></i>
                            )}
                        </div>
                        <h3 key='3'>{name}</h3>
                        <img src={picture} alt="" key='4'/>
                        
                        <span className="infos" key='6'>
                            <i className="fas fa-plus-circle" onClick={this.handleInfo}></i>
                        </span>

                        {
                            this.state.showInfo && (
                                <div className="showInfos">
                                    <div className="infosContent">
                                        <div className="head">
                                            <h2>{name}</h2>
                                            <div className="sourceCode">
                                                <a href={source} rel="noopener noreferrer" className="button" target="_blank"><i class="fas fa-eye"></i></a>
                                            </div>
                                        </div>
                                        <h4 key='5'>{title}</h4>
                                        <p className="text">{info}</p>
                                        
                                        <div className="button return" onClick={this.handleInfo}>Fermer</div>
                                    </div>                   
                                </div>
                            ) 
                        }
                    
                </QueueAnim> 
            </div>      
        )
    }
}
