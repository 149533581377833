import React, { useState, useRef, useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import {contactComponent} from '../components/Animate';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_a1rzGWbNJPzu6bsyBR9dT");


const Contact = () => {

    //using useRef hook to access the contactComponent DOM
    let contact = useRef(null)
    useEffect(() => {
        contactComponent(contact)
    }, [])

    //Validation
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const isEmail = () => {
        let mail = document.getElementById('not-email');
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.match(regex)) {
            mail.style.visibility = 'hidden';
           return true;
        } else {
            mail.style.visibility = 'visible';
            mail.style.animation = 'dongle 1s';
            setTimeout(() => {
                mail.style.animation = 'none';
            }, 1000);
            return false;
        }
    }

    const isMsg = () => {
        let msg = document.getElementById('not-msg');
        let msgValue = "";

        if (message !== msgValue) {
            msg.style.visibility = 'hidden';
            return true;
        } else {
            msg.style.visibility = 'visible'; 
            msg.style.animation = 'dongle 1s';
            setTimeout(() => {
                msg.style.animation = 'none';
            }, 1000);
            return false;
        }
    }

    const isName = () => {
        let nom = document.getElementById('not-name');
        let nameValue = "";

        if (name !== nameValue) {
            nom.style.visibility = 'hidden';
            return true;
        } else {
            nom.style.visibility = 'visible';
            nom.style.animation = 'dongle 1s';
            setTimeout(() => {
                nom.style.animation = 'none';
            }, 1000);
            return false;
        }
    }

    const failMessage = (message) => {
        let formMess = document.querySelector('.form-message');

        formMess.innerHTML = message;

        formMess.style.display = 'block';
        formMess.style.opacity = '1';
        formMess.style.background = 'rgb(253,87,87)';
        formMess.style.animation = 'dongle 1s';

        setTimeout(() => {
            formMess.style.animation = 'none';
        }, 1000);

    };

    const successMessage = () => {
        let formMess = document.querySelector('.form-message');

        formMess.innerHTML = 'Message envoyé !';

        formMess.style.opacity = '1';
        formMess.style.background = '#00c1ec';

        document.getElementById('email').classList.remove('error');
      

        setTimeout(() => {
            formMess.style.opacity= '0';
        }, 5000);
    }

    // Border inputs
    const validationInput = () => {
        let inputName =  document.querySelector('#name');
        let inputEmail =  document.querySelector('#email');
        let inputMessage =  document.querySelector('#message');
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let nameValue = "";
        let msgValue = "";
       
        if(inputName.value !== nameValue) {
            inputName.style.borderColor = "green";
        } else {
            inputName.style.borderColor = "red";
        }

        if (inputEmail.value.match(regex)) {
            inputEmail.style.borderColor = "green";
        } else {
            inputEmail.style.borderColor = "red";
        }

        if (inputMessage.value !== msgValue) {
            inputMessage.style.borderColor = "green";
        } else {
            inputMessage.style.borderColor = "red";
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (isName() & isMsg() & isEmail()) {
            sendFeedback('template_8ag5p1p', {
                name,
                email,
                message,
            });
        } else {
            failMessage("Tous les champs ne sont pas correctement rempli.<br>Veuillez vérifier votre saisie.");
            validationInput();
        }
    };

    const sendFeedback = (templateId, variables) => {

        emailjs
            .send('gmail', templateId, variables)
            .then((res) => {
                successMessage();
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch(
                (e) =>
                failMessage("Une erreur s'est produite, veuilez réessayer.")
            )
    };

    return(
        <div className="contact">
            <Navigation />
            <div className="contactContent">
                <div className="contactBox" ref={(el) => (contact = el)}>
                    <h1>Contactez-moi</h1>
                    <form id="contact-form" method="POST">
                        <div className="inputs">
                            <div className="name">
                                <input 
                                    type="text" 
                                    id="name"
                                    name="name"
                                    placeholder="Votre nom"
                                    onChange={(e) => setName(e.target.value)} 
                                    value={name}
                                    
                                />
                                <label id="not-name">Qui êtes-vous ?</label>
                            </div>

                            <div className="email">
                                <input 
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Votre adresse email"
                                    onChange={(e) => setEmail(e.target.value)} 
                                    value={email}
                                    
                                />
                                <label id="not-email">Cet email n'est pas valide</label>
                            </div>
                        </div>

                        <div className="message">
                            <textarea 
                                rows="10" 
                                id="message"
                                name="message" 
                                placeholder="Votre message"
                                onChange={(e) => setMessage(e.target.value)}  
                                value={message}
                                 
                            />
                            <label id="not-msg">Un petit message s'il vous plait !</label>
                        </div>

                        <div className="btnWidth">
                            <input 
                                type="submit" 
                                className="button"
                                value="Envoyer"
                                onClick={handleSubmit}
                            />                           
                        </div>
                       <div className="form-message"></div>
                    </form>
                </div> 
            </div>
            <footer>
                <Footer />
            </footer> 
        </div>
      );
}
  
export default Contact;




