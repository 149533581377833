import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../components/Footer'

const Navigation = () => {
    return ( 
        <div className="nav">
            <div className="id">
                <div className="idContent">
                    <img src="./media/photo.jpg" alt="profil-pic" />
                    <h1>Rémy Galopin <br /> Développeur Web</h1> 
                </div>
            </div>
            

            <div className="navigation">
                <ul>
                    <li>
                        <NavLink exact to="/" activeClassName="navActive">
                            <i className="fas fa-home"></i>
                            <span>Accueil</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/competences" activeClassName="navActive">
                            <i className="fas fa-laptop-code"></i>
                            <span>Compétences</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/portfolio" activeClassName="navActive">
                            <i className="fas fa-images"></i>
                            <span>Portfolio</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/contact" activeClassName="navActive">
                            <i className="fas fa-address-book"></i>
                            <span>Contact</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <Footer />
        </div>
    );
};

export default Navigation;