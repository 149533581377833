import React, { useEffect, useRef } from 'react';
import {hobbiesComponent} from '../Animate';

const Hobbies = () => {
    
    //using useRef hook to access the otherComponent DOM
    let hobbies = useRef(null)
    useEffect(() => {
        hobbiesComponent(hobbies)
    }, [])

    return (
        <div className="hobbies"  ref={(el) => (hobbies = el)}>
            <h3>Intêrets</h3>
            <ul>
                <li className="hobby">
                    <i className="far fa-file-code"></i>
                    <span>Développement</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-film"></i>
                    <span>Cinéma</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-gamepad"></i>
                    <span>Jeux vidéo</span>
                </li>
                <li className="hobby">
                    <i className="fas fa-dumbbell"></i>
                    <span>Fitness</span>
                </li>
            </ul>
        </div>
    );
};

export default Hobbies;