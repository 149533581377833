import React, { useRef, useEffect } from 'react';
import {footerComponent} from '../components/Animate';

const Footer = () => {

    //using useRef hook to access the footerComponent DOM
    let footer = useRef(null)
    useEffect(() => {
        footerComponent(footer)
    }, [])

    return (
        
        <div className="socialNetworks" ref={(el) => (footer = el)}>
            <ul>
                <li title="LinkedIn">
                    <a href="https://www.linkedin.com/in/rémy-galopin/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                </li>
                <li title="GitHub">
                    <a href="https://github.com/Legacy0013" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-github-alt"></i>
                    </a>
                </li>
                <li title="CV PDF">
                    <a href="./media/CV.pdf" target="_blank" rel="noopener noreferrer">
                        <i class="fas fa-download"></i>
                    </a>
                </li>
            </ul>
            <div className="signature">
                <p>Rémy GALOPIN &copy; 2021, Tous droits réservés.</p>
            </div>
        </div>
    );
};

export default Footer;