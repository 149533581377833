import gsap from "gsap"

//Animate expériences component 
export const expComponent = elem => {
  gsap.from(elem, {
    yPercent: -200,
    opacity: 0,
    stagger: 0.2,
    duration: 3,
    // scale: -1,
    ease: "power3",
  });
};

//Animate otherSkills component 
export const otherComponent = elem => {
    gsap.from(elem, {
      xPercent: -200,
      opacity: 0,
      stagger: 0.2,
      duration: 3,
      scale: -1,
      ease: "power3",
    });
  };
  
  //Animate hobbies component 
export const hobbiesComponent = elem => {
    gsap.from(elem, {
      xPercent: 200,
      opacity: 0,
      stagger: 0.2,
      duration: 3,
      scale: -1,
      ease: "power3",
    });
  };

    //Animate contact component 
export const contactComponent = elem => {
  gsap.from(elem, {
    yPercent: 200,
    opacity: 0,
    stagger: 0.5,
    duration: 1,
    scale: -1,
    ease: "back",
  });
};
    //Animate contact component 
    export const footerComponent = elem => {
      gsap.from(elem, {
        yPercent: 200,
        opacity: 0,
        stagger: 0.5,
        duration: 1,
        scale: -1,
        ease: "power3",
      });
    };
  
