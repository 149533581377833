import React, { Component } from 'react'
import ProgressBar from './ProgressBar';

class Languages extends Component {
    state = {
            languages: [
                {id: 1, value: "Html / Css", xp: 2},
                {id: 2, value: "Sass", xp: 1},
                {id: 3, value: "Javascript", xp: 1.5},
                {id: 4, value: "Php", xp: 1},
                {id: 5, value: "Java", xp: 1},
                {id: 6, value: "Sql", xp: 1}
            ],
            frameworks: [
                {id: 1, value: "Bootsrap", xp: 1.5},
                {id: 2, value: "Angular", xp: 1},
                {id: 3, value: "Wordpress", xp: 0.5},
                {id: 4, value: "React", xp: 0.5}
            ]
        }

    render() {
        let {languages, frameworks} = this.state;

        return (
            <div className="languagesFrameworks">
                <ProgressBar 
                    data={languages}
                    className="languagesDisplay"
                    title="Languages"
                />
                <ProgressBar 
                    data={frameworks}
                    className="frameworksDisplay"
                    title="Frameworks, CMS & autres"
                />
            </div>
        );
    }
}

export default Languages