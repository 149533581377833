import React from 'react';
import Typical from 'react-typical';
import Navigation from '../components/Navigation';
import ManWork from '../components/WorkMan';
import Footer from '../components/Footer'

const Home= () => {

    return (    
            <div className="home">
                <Navigation/>
                <div className="homeContent">
                    <div className="content">
                        <h1>Bonjour, bienvenue sur mon portfolio</h1>
                        <h2>Je suis{' '}

                            <Typical
                                loop={Infinity}
                                wrapper="b"
                                steps={[
                                    'Rémy Galopin', 1000,
                                    'développeur web', 1000,
                                    'web designer', 1000,
                                    'passionné de nouvelles technologies', 1000,
                                    'diplômé et autodidacte', 1000,
                                ]}
                            />
                        </h2>
                       <ManWork/>
                    </div>
                    
                </div>
                   <footer>
                        <Footer />
                    </footer> 
            </div>      
                                               
    );
};

export default Home;