import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import ProjectList from '../components/portfolio/ProjectList';

const Portfolio = () => {
    return (
        <div className="portfolio">
            <Navigation />
            <ProjectList />
            <footer>
                <Footer />
            </footer>
            
        </div>
    );
};

export default Portfolio;